import React from 'react';
import Button from '@material-ui/core/Button';

export default function SignInPage() {
  return (
    <div>
      <div>Sign up</div>
      <Button variant="contained">
        Sign up
      </Button>
    </div>
  );
}
