import React, { useState } from 'react';
import {
  Modal, TextField, Grid, Button, Typography, Divider
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, string } from 'prop-types';
import RequestForgetInfo from './RequestForgetInfo';
import RequestCompensationInfo from './RequestCompensationInfo';
import { getManagerRequestCount } from '../../state/modules/user/actions';
import { statusLeaves } from '../../state/modules/requests/reducer';

import { styles } from './styles';
import { boolean } from 'joi';
import { upperFirst } from 'lodash';

const useStyles = makeStyles({
  ...styles,
});

export default function CommentMultipleModalComponent({
  open,
  onClose,
  onSubmit,
  requests,
  selectedIds,
  isForAdmin = false
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [commentValue, setCommentValue] = useState('');
  const [commentError, setCommentError] = useState('');

  const handleCloseModal = () => {
    onClose?.();
  };

  const validateForm = () => {
    if (!commentValue) {
      setCommentError('Comment is required');
      return false;
    }
    return true;
  };

  const handleChangeComment = (e) => {
    setCommentError('');
    setCommentValue(e.target.value);
  };

  const handleSubmitRequest = (type) => () => {
    if (validateForm()) {
      onSubmit({
        selectedIds,
        comment: commentValue,
        status: type,
      });
      dispatch(getManagerRequestCount());
      handleCloseModal();
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        <div className={classes.bodyModalStyles}>
          <Grid container spacing={8}>
            <div className={classes.groupTitle}>Request Information</div>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              {selectedIds.map((id) => {
                const request = requests.find(item => item.id === id)
                return <div>
                  <div>
                    {`${request?.badgeNumber} - ${request?.name} - ${upperFirst(request?.requestTypeName)}`}
                  </div>
                  <div>{request?.comment}</div>
                  <Divider style={{ marginTop: '5px',  marginBottom: '5px' }}/>
                </div>
              })}
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <div className={classes.groupTitle}>Review Request</div>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <TextField
                required
                id="outlined-required"
                label="Comment"
                value={commentValue}
                onChange={handleChangeComment}
                variant="outlined"
                style={{ width: '100%' }}
                error={commentError}
                helperText={commentError}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={8}
          >
            <Button
              variant="contained"
              style={{ margin: '20px 10px' }}
              color="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              variant="contained"
              style={{ margin: '20px 10px' }}
              onClick={handleSubmitRequest(statusLeaves.REJECTED)}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              style={{ margin: '20px 10px' }}
              color="primary"
              onClick={handleSubmitRequest(
                isForAdmin ? statusLeaves.APPROVED : statusLeaves.CONFIRMED
              )}
            >
              {isForAdmin ? 'Approve' : 'Confirm'}
            </Button>
          </Grid>
        </div>
      </Modal>
    </>
  );
}

CommentMultipleModalComponent.propTypes = {
  openProps: bool,
  onSubmit: func,
  setOpenProps: func,
  isForAdmin: string,
  commentOnly: boolean
};
