const initialState = {
  route: '',
};

export function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
