export const styles = {
  childDiv: {
    display: 'block'
  },
  childDivFlex: {
    display: 'flex'

  },
  childContainer: {
    padding: '90px 40px 80px 80px',
    width: 'calc(100% - 120px)'
  },
  childContainerExpand: {
    padding: '90px 30px 80px 30px',
    width: 'calc(100% - 60px - var(--expand))'
  },
  root: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '50px',
    '& > * + *': {
      marginTop: '10px',
    },
  }
};
