import { all, takeLatest, put } from 'redux-saga/effects';
import * as configActions from './actions';
import apiRoute from '../../../../apiRoute.json';

function* setConfig() {
  const globalConfig = window.config || {};
  const serverURL = 'http://localhost:3001';
  const rowPerPage = 10;
  yield put(configActions.setConfig({
    serverURL,
    rowPerPage,
    ...globalConfig,
    apiRoute
  }));
  yield put(configActions.setConfigDone());
}

export function* sagaFlow() {
  yield all([
    takeLatest(configActions.REQUEST_SET_CONFIG, setConfig)
  ]);
}
