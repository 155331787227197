import { namespace } from './selector';

export const CREATE_ALL_WORK_SCHEDULE = `timesheet/${namespace}/CREATE_ALL_WORK_SCHEDULE`;
export const CHECK_REGISTER_WORK_SCHEDULE = `timesheet/${namespace}/CHECK_REGISTER_WORK_SCHEDULE`;
export const CHECK_CURRENT_WORK_SCHEDULE_DONE = `timesheet/${namespace}/CHECK_CURRENT_WORK_SCHEDULE_DONE`;
export const SET_CHECK_CURRENT_WORK_SCHEDULE = `timesheet/${namespace}/SET_CHECK_CURRENT_WORK_SCHEDULE`;
export const DELETE_WORK_SCHEDULE = `timesheet/${namespace}/DELETE_WORK_SCHEDULE`;

export const checkUserRegisterWorkerSchedule = () => ({
  type: CHECK_REGISTER_WORK_SCHEDULE,
});

export const createAllWorkSchedule = ({ payload, callback }) => ({
  type: CREATE_ALL_WORK_SCHEDULE,
  payload: { payload, callback },
});
export const checkCurrentUserWorkScheduleDone = () => ({
  type: CHECK_CURRENT_WORK_SCHEDULE_DONE,
});

export const setCurrentWorkSchedule = (workSchedule) => ({
  type: SET_CHECK_CURRENT_WORK_SCHEDULE,
  payload: workSchedule,
});

export const requestPutWorkSchedule = ({ payload, callback }) => ({
  type: DELETE_WORK_SCHEDULE,
  payload: {
    payload,
    callback,
  },
});
