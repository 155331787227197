import MomentUtils from '@date-io/moment';
import {
  Grid, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { object } from 'prop-types';


import { styles } from './styles';
import { LeaveReasonSelect } from '../LeaveReasonComponent';

const useStyles = makeStyles({
  ...styles
});

const originForm = {
  comment: '',
  errorCount: false
};

export default function LeaveInfo({
  openProps,
}) {
  const classes = useStyles();
  const [formModalValue, setFormModalValue] = useState(originForm);

  useEffect(() => {
    if (openProps) {
      setFormModalValue({
        createdDateTime: moment(openProps.createdDateTime),
        comment: openProps && openProps.comment,
        startDateTime: moment(openProps.startDateTime),
        endDateTime: moment(openProps.endDateTime),
        offlineTime: openProps.offTimeHour,
        requestType: openProps.reasonId
      });
    }
  }, [openProps]);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY HH:mm"
              // margin="normal"
              id="start-date-picker-inline"
              label="Start Date"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              style={{ width: '100%' }}
              value={formModalValue.startDateTime}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY HH:mm"
              // margin="normal"
              id="end-date-picker-inline"
              label="End Date"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              style={{ width: '100%' }}
              value={formModalValue.endDateTime}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              id="created-date-picker-inline"
              label="Created Date"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              style={{ width: '100%' }}
              value={formModalValue.createdDateTime}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled
              required
              id="time-requested"
              label="Time Requested"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              style={{ width: '100%' }}
              value={formModalValue.offlineTime}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LeaveReasonSelect label={"Request Type"} disabled value={formModalValue.requestType}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled
              required
              id="comment"
              label="Reason"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              style={{ width: '100%' }}
              value={formModalValue.comment}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

    </>
  );
}

LeaveInfo.propTypes = {

  openProps: object,

};

LeaveInfo.defaultProps = {
  openProps: {},

};
