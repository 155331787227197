import {
  all, call, put, select, takeLatest
} from 'redux-saga/effects';

import { getApi, postApi, putApi } from '../../../api/apiHelper';
import { configSelector } from '../config/selector';
import * as workScheduleActions from './actions';

function* createAllWorkSchedule({ payload: { payload, callback } }) {
  const { serverURL, apiRoute } = yield select(configSelector);
  const response = yield call(postApi, {
    apiRoot: serverURL,
    apiRoute: `${apiRoute.createWorkSchedule}`,
    payloadData: payload,
  });
  if (callback) {
    callback(response);
  }
}

function* checkWorkSchedule() {
  const { serverURL, apiRoute } = yield select(configSelector);
  const request = yield call(getApi, {
    apiRoot: serverURL,
    apiRoute: `${apiRoute.checkMemberRegisterWorkSchedule}`,
  });
  if (request) {
    yield put(workScheduleActions.setCurrentWorkSchedule(request));
  }
  yield put(workScheduleActions.checkCurrentUserWorkScheduleDone());
}

function* deleteWorkSchedule({ payload: { payload, callback } }) {
  const { serverURL, apiRoute } = yield select(configSelector);
  const request = yield call(putApi, {
    apiRoot: serverURL, apiRoute: apiRoute.deleteWorkSchedule, payloadData: payload
  });
  if (callback) callback(request);
}

export function* sagaFlow() {
  yield all([
    takeLatest(
      workScheduleActions.CHECK_REGISTER_WORK_SCHEDULE,
      checkWorkSchedule
    ),
    takeLatest(
      workScheduleActions.CREATE_ALL_WORK_SCHEDULE,
      createAllWorkSchedule
    ),
    takeLatest(
      workScheduleActions.DELETE_WORK_SCHEDULE,
      deleteWorkSchedule
    ),
  ]);
}
