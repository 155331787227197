export const styles = (theme) => ({
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyModalStyles: {
    backgroundColor: '#fff',
    border: '0 !important',
    width: '50%',
    padding: '50px 20px',
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  accountInput: {
    width: '100%',
  },
  groupTitle: {
    borderLeft: '4px solid #18191b',
    margin: '10px',
    paddingLeft: 10,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  headerTable: {
    backgroundColor: '#000000c9',
    color: '#fff',
    fontWeight: 600,
    border: '1px solid #fff',
    padding: 5,
  },
  bodyTable: {
    border: '1px solid #dadce0',
    padding: 5,
  },
  typography: {
    paddingTop: theme.spacing(2),
  },
  dayOfWeekBtn: {
    margin: '15px !important',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
    borderRadius: '50px !important',
    width: '50px',
    fontWeight: 600

  },
  bgBtn: {
    backgroundColor: '#2962ff !important',
    color: 'white !important',
    fontWeight: 600
  },
  groupBtn: {
    marginTop: 20,
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  warningText: {
    paddingTop: theme.spacing(3),
    color: 'red',
  },
  bodyModal: {
    backgroundColor: '#fff',
    border: '0 !important',
    width: '40%',
    padding: '50px 20px',
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
  },
});
