import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  func, object, oneOfType, string
} from 'prop-types';
import React from 'react';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

export default function CustomConfirmAlert({
  isOpen,
  onOpenPropsModal,
  title,
  content,
  onConfirmAction
}) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        onClose={onOpenPropsModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={onOpenPropsModal}>
          <span className={classes?.textTitle}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={classes?.textTitle}>{content}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmAction} color="primary">
            Yes, Im sure
          </Button>
          <Button onClick={onOpenPropsModal} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CustomConfirmAlert.propTypes = {
  isOpen: oneOfType([object]).isRequired,
  onOpenPropsModal: func,
  title: string.isRequired,
  content: string.isRequired,
  onConfirmAction: func
};

CustomConfirmAlert.defaultProps = {
  onOpenPropsModal: () => {},
  onConfirmAction: () => {}
};
