/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Grid,
  TextField,
  Chip,
  Button,
  Tooltip,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AlarmIcon from '@material-ui/icons/Alarm';
import RestoreIcon from '@material-ui/icons/Restore';
import AuthenticatedContainer from '../../containers/AuthenticatedContainer';

import { HeaderWrapperComponent } from '../../components/HeaderWrapperComponent';
import CustomTable from '../../components/CustomTable';
import { FilterComponent } from '../../components/FilterComponent';
import UserCRUD from '../../components/UserManagementComponent/UserCRUD';
import { RoleListComponent } from '../../components/RoleListComponent';
import ChangePassword from '../../components/UserManagementComponent/ChangePassword';
import ManagerListComponent from '../../components/ManagerListComponent';
import WorkingScheduleSetting from '../../components/UserManagementComponent/WorkingScheduleSetting';
import UserStatusFilterComponent, {
  userStatusFilter,
} from '../../components/UserStatusFilterComponent';

import {
  requestGetCurrentUser,
  requestGetListUser,
  requestPutUser,
} from '../../state/modules/user/actions';
import { showNotification } from '../../state/modules/notification/actions';

import { styles } from './styles';
import PaginationFilterComponent from '../../components/PaginationFilterComponent';
import { defaultLimit, maxRowPerPage } from '../../global/constants';
import { transformRuleToText } from '../../helper/helper';
import AddWorkSchedule from '../../components/UserManagementComponent/AddWorkSchedule';
import {
  checkUserRegisterWorkerSchedule,
  requestPutWorkSchedule,
} from '../../state/modules/workSchedule/actions';
import CustomConfirmAlert from '../../components/CustomConfirmAlert';

const originalForm = {
  badgeNumber: '',
  title: null,
  name: null,
  status: '',
  managerId: '',
  email: '',
  limit: defaultLimit,
};

const useStyles = makeStyles({
  ...styles,
});

function UserManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(defaultLimit);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentScheduleChecked, setCurrentScheduleChecked] = useState('');
  const [formValue, setFormValue] = useState({
    ...originalForm,
    // status: userStatusFilter.ACTIVE,
    limit,
  });

  const [openModalProps, setOpenModalProps] = useState(false);

  const [openModalChangePassProps, setOpenModalChangePassProps] = useState({});

  const [openModalWorkSchedule, setModalWorkSchedule] = useState(false);
  const [openModalRecoverAccount, setOpenModalRecoverAccount] = useState(false);

  const [
    openModalWorkingScheduleProps,
    setOpenModalWorkingScheduleProps,
  ] = useState({});

  const handleFetchUsers = ({ filter }) => {
    dispatch(
      requestGetListUser({
        filter,
        callback: (res) => {
          if (res && res.error) {
            dispatch(
              showNotification('failed', res.error.message || res.error, true)
            );
          } else {
            setDataSource(res.data);
            if (res.pagination) {
              setPagination({
                current: res.pagination.page,
                total: res.pagination.count,
                count: Math.ceil(
                  res.pagination.count / (filter.limit || limit)
                ),
              });
            }
          }
        },
      })
    );
  };

  const handleFetchReadyToFetchUser = (dataFilter, page) => {
    const filterObj = {};
    if (dataFilter.badgeNumber) {
      filterObj.badgeNumber = dataFilter.badgeNumber.trim();
    }
    if (dataFilter.email) filterObj.email = dataFilter.email.trim();
    if (dataFilter.name) filterObj.name = dataFilter.name.trim();
    if (dataFilter.title && dataFilter.title.label) {
      filterObj.title = dataFilter.title.label;
    }
    if (dataFilter.managerId && dataFilter.managerId.value) {
      filterObj.managerId = dataFilter.managerId.value;
    }
    if (dataFilter.status) {
      filterObj.status = dataFilter.status;
    }
    if (dataFilter.limit) {
      filterObj.limit = dataFilter.limit;
    }
    filterObj.page = page || 1;
    handleFetchUsers({ filter: filterObj });
  };

  const handleChangeTable = (e, page) => {
    setIsCheckAll(false);
    setListChecked([]);
    handleFetchReadyToFetchUser(formValue, page);
  };

  const handleKeyDownFilter = (event) => {
    if (event.keyCode === 13) {
      handleFetchReadyToFetchUser(formValue, 1);
    }
  };

  const refreshListUser = () => {
    handleFetchReadyToFetchUser(formValue, pagination.current);
    setListChecked([]);
    setIsCheckAll(false);
  };

  useEffect(() => {
    handleFetchReadyToFetchUser(
      {
        ...originalForm,
        status: userStatusFilter.ACTIVE,
      },
      1
    );
  }, []);

  const handleFormChange = (type) => (event) => {
    const dataChange = {
      ...formValue,
      [type]: event.target.value && event.target.value,
    };
    if (type === 'limit') {
      const newLimit = Number(event.target.value) || maxRowPerPage;
      dataChange.limit = newLimit;
      setLimit(newLimit);
    }
    setFormValue(dataChange);
    if (
      type === 'title'
      || type === 'managerId'
      || type === 'name'
      || type === 'status'
      || type === 'limit'
    ) {
      handleFetchReadyToFetchUser(dataChange);
    }
  };

  const handleAddUser = () => {
    setEditUser({});
    setOpenModalProps(true);
  };

  const handleEditUser = (data) => () => {
    setEditUser(data);
    setOpenModalProps(true);
  };

  const handleEditPasswordUser = (data) => () => {
    setOpenModalChangePassProps(data);
  };
  const handleOpenModalWorkSchedule = () => {
    setModalWorkSchedule((pre) => !pre);
  };
  const handleOpenModalRecoverAccount = (data) => () => {
    if (data) {
      setOpenModalRecoverAccount(data);
    } else {
      setOpenModalRecoverAccount(false);
    }
  };
  const handleRecoverAccount = () => {
    dispatch(
      requestPutUser({
        payload: { id: openModalRecoverAccount?.id, status: 'active' },
        callback: (res) => {
          if (res && res.error) {
            dispatch(
              showNotification('failed', res.error.message || res.error, true)
            );
          } else {
            refreshListUser();
            setOpenModalRecoverAccount(false);
            dispatch(showNotification('success', 'Recover account successfully!'));
          }
        },
      })
    );
  };
  const handleEditWorkingSchedule = (data) => () => {
    setOpenModalWorkingScheduleProps(data);
  };

  const handleDeleteItemFilter = (type) => () => {
    const dataChange = { ...formValue, [type]: originalForm[type] };
    handleFetchReadyToFetchUser(dataChange, 1);
    setFormValue(dataChange);
  };

  const handleCheckAll = () => {
    setIsCheckAll((preStateCheckAll) => !preStateCheckAll);
    if (!isCheckAll) {
      setListChecked(dataSource.map((item) => `${item.id}`));
    } else {
      setListChecked([]);
    }
  };

  const handleClickCheckBox = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setListChecked([...listChecked, id]);
      setCurrentScheduleChecked(
        dataSource?.find((item) => item.id === id)?.schedule
      );

      if (listChecked?.length === dataSource?.length - 1) {
        setIsCheckAll(true);
      }
    } else {
      setListChecked(listChecked.filter((item) => item !== id));
      setIsCheckAll(false);
      setCurrentScheduleChecked(
        dataSource?.find(
          (item) => item.id !== id && listChecked.includes(item?.id)
        )?.schedule
      );
    }
  };
  const handleDeleteWorkSchedule = () => {
    dispatch(
      requestPutWorkSchedule({
        payload: {
          userId: listChecked,
        },
        callback: (res) => {
          if (res && res.error) {
            dispatch(
              showNotification('failed', res.error.message || res.error, true)
            );
          } else {
            dispatch(
              showNotification('success', 'Delete schedule successfully!')
            );
            dispatch(requestGetCurrentUser());
            dispatch(checkUserRegisterWorkerSchedule());
            refreshListUser();
          }
        },
      })
    );
  };
  const columns = [
    {
      label: (
        <Checkbox checked={isCheckAll} className="" onChange={handleCheckAll} />
      ),
      field: 'select',
      render: (_, __, row) => (
        <Tooltip title={row.badgeNumber}>
          <Checkbox
            id={row.id}
            checked={listChecked.includes(`${row.id}`)}
            onChange={handleClickCheckBox}
            color="primary"
          />
        </Tooltip>
      ),
    },
    {
      field: 'badgeNumber',
      label: 'ID',
    },
    {
      field: 'name',
      label: 'Full Name',
    },
    {
      field: 'phone',
      label: 'Phone',
    },
    {
      field: 'birthDay',
      label: 'Birthday',
      render: (text) => text && moment(text).format('DD/MM/YYYY'),
    },
    {
      field: 'email',
      label: 'Email',
    },
    {
      field: 'title',
      label: 'Grant',
    },
    {
      field: 'managerName',
      label: 'Manager',
      render: (text, index, row) => (
        <span>{`${text || '//'} - ${row.managerBadgeNumber || '//'}`}</span>
      ),
    },
    {
      field: 'lineManagerName',
      label: 'Line Manager',
      render: (text, index, row) => (
        <>
          {text ? <span>{`${text || '//'} - ${row.lineManagerBadgeNumber || '//'}`}</span> : '-'}
        </>

      ),
    },
    {
      field: 'hiredDate',
      label: 'Start Date',
      render: (text) => text && moment(text).format('DD/MM/YYYY'),
    },
    {
      field: 'schedule',
      label: 'Work Offline Schedule',
      render: (text) => transformRuleToText(text),
    },
    // {
    //   field: 'status',
    //   label: 'Status'
    // },
    {
      label: 'Action',
      render: (text, index, row) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={handleEditUser(row)}
              color="#000"
              aria-label="delete"
              className={classes.actionButton}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={handleEditPasswordUser(row)}
            color="#000"
            className={classes.actionButton}
            disabled={row.title === 'admin'}
          >
            <Tooltip title="Change Password">
              <VpnKeyIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            size="small"
            onClick={handleEditWorkingSchedule(row)}
            color="#000"
            className={classes.actionButton}
          >
            <Tooltip title="Working Schedule">
              <AlarmIcon />
            </Tooltip>
          </IconButton>
          {row?.status === 'inactive' && (
            <IconButton
              size="small"
              onClick={handleOpenModalRecoverAccount(row)}
              color="#000"
              className={classes.actionButton}
            >
              <Tooltip title="Recover Account">
                <RestoreIcon />
              </Tooltip>
            </IconButton>
          )}
        </>
      ),
    },
  ];
  return (
    <AuthenticatedContainer>
      <Grid container>
        <HeaderWrapperComponent
          title="User Management"
          action={(
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right', marginLeft: 10 }}
                onClick={handleAddUser}
              >
                Add User
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={listChecked?.length === 0}
                style={{ float: 'right', marginLeft: 10 }}
                onClick={handleOpenModalWorkSchedule}
              >
                Work In Office
                {' '}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(dataSource || [])
                    ?.filter((item) => listChecked.includes(item.id))
                    ?.some((item) => item?.schedule)
                }
                style={{ float: 'right', marginLeft: 10 }}
                onClick={handleDeleteWorkSchedule}
              >
                Delete Schedule
              </Button>
            </>
          )}
        />
      </Grid>
      <Grid container>
        <FilterComponent
          listFilterRender={(
            <>
              {Object.keys(formValue).map(
                (key) => formValue[key] && (
                  <Chip
                    key={key}
                    label={
                      formValue[key].label
                        ? formValue[key].label
                        : formValue[key]
                    }
                    className={classes.filterItem}
                    onDelete={handleDeleteItemFilter(key)}
                  />
                )
              )}
            </>
          )}
          formFilter={(
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="badgeNumber"
                    label="ID"
                    style={{ width: '100%' }}
                    value={formValue.badgeNumber}
                    onChange={handleFormChange('badgeNumber')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyDown={handleKeyDownFilter}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="name"
                    label="Name"
                    style={{ width: '100%' }}
                    value={formValue.name}
                    onChange={handleFormChange('name')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyDown={handleKeyDownFilter}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="email"
                    label="Email"
                    style={{ width: '100%' }}
                    value={formValue.email}
                    onChange={handleFormChange('email')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyDown={handleKeyDownFilter}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <PaginationFilterComponent
                    value={limit}
                    onChange={handleFormChange('limit')}
                    displayEmpty
                    style={{ width: '100%' }}
                    labelInValue
                    inputLabel="Row Per Page"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <RoleListComponent
                    value={formValue.title}
                    onChange={handleFormChange('title')}
                    displayEmpty
                    style={{ width: '100%' }}
                    labelInValue
                    inputLabel="Grant"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ManagerListComponent
                    value={formValue.managerId}
                    onChange={handleFormChange('managerId')}
                    displayEmpty
                    style={{ width: '100%' }}
                    labelInValue
                    inputLabel="Manager"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <UserStatusFilterComponent
                    value={formValue.status}
                    onChange={handleFormChange('status')}
                    style={{ width: '100%' }}
                    inputLabel="Status"
                  />
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
      <CustomTable
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        onChange={handleChangeTable}
      />
      <UserCRUD
        openProps={openModalProps}
        setOpenPropsModal={setOpenModalProps}
        refreshListUser={refreshListUser}
        editUser={editUser}
      />

      <ChangePassword
        openProps={openModalChangePassProps}
        setOpenPropsModal={setOpenModalChangePassProps}
      />
      <AddWorkSchedule
        open={openModalWorkSchedule}
        onCloseModal={handleOpenModalWorkSchedule}
        listUser={listChecked}
        onFetchPage={refreshListUser}
        currentSchedule={currentScheduleChecked}
      />
      <WorkingScheduleSetting
        openProps={openModalWorkingScheduleProps}
        setOpenPropsModal={setOpenModalWorkingScheduleProps}
      />
      <CustomConfirmAlert
        isOpen={openModalRecoverAccount}
        onOpenPropsModal={handleOpenModalRecoverAccount(false)}
        title="Recover Account"
        content="This account has been removed from the activity list, are you sure want to restore it?"
        onConfirmAction={handleRecoverAccount}
      />
    </AuthenticatedContainer>
  );
}

export default UserManagement;
