import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const useStyles = makeStyles({
  ...styles,
});
export function Footer() {
  const classes = useStyles();
  return (
    <>
      <footer className={classes?.footer}>
        <span>
          Copyright ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          <a
            href="https://seta-international.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SETA INTERNATIONAL
          </a>
          <span>
            &nbsp;- All rights Reserved
          </span>
        </span>
      </footer>
    </>
  );
}
