export const styles = {
  listFilter: {
    display: 'inline',
  },
  helperWrapper: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    marginTop: '30px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterItem: {
    margin: '0px 5px'
  },
  filterContainer: {
    display: 'flex',
    gap: '20px'
  },
  filterArea: {
    borderRadius: 5,
    margin: 20
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center'
  }
};
