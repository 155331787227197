/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddWorkScheduleAlert from '../../components/AddWorkScheduleAlert';
import AppBar from '../../components/AppBar';
import { Footer } from '../../components/Footer';
import { LeftBarMenu } from '../../components/LeftBarMenu';
import { routePath } from '../../helper/constants';

import routes from '../../routeMap';
import { userSelector } from '../../state/modules/user/selector';
import { workScheduleSelector } from '../../state/modules/workSchedule/selector';
import { styles } from './styles';
import { useDrawerState } from '../../provider/DrawerProvider';

const useStyles = makeStyles({ ...styles });

export default function AuthenticatedContainer({ children }) {
  const { user } = useSelector(userSelector);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useSelector(userSelector);
  const { checkWorkSchedule } = useSelector(workScheduleSelector);
  const { open } = useDrawerState();

  useEffect(() => {
    const findRole = routes.find((route) => route.path === location.pathname)
      && routes.find((route) => route.path === location.pathname).role;
    const hasPermission = findRole && permissions.includes(findRole);

    if (!hasPermission) history.push(routePath.SIGN_IN_PATH);
  }, []);

  const handleActionButton = () => {
    window.location.href = routePath.REGISTER_BIOMETRIC;
  };

  const handleRedirectToManagerPage = () => {
    window.location.href = routePath.MEMBER_MANAGEMENT_PATH;
  };

  return (
    <>
      <AppBar />
      <div className={open ? classes.childDivFlex : classes.childDiv}>
        <LeftBarMenu />
        <div
          className={open ? classes?.childContainerExpand : classes?.childContainer}
        >
          {children}
        </div>
      </div>
      <div className={classes.root}>
        {!user.hasBiometric
          && location.pathname !== routePath.REGISTER_BIOMETRIC && (
            <AddWorkScheduleAlert
              onClickAlertAction={handleActionButton}
              nameAlert="biometric"
              content="You haven't register biometric login."
              buttonItem="register"
              hasAction
            />
        )}
        {checkWorkSchedule?.checkCurrentUserNotRegister && (
          <AddWorkScheduleAlert
            hasAction={false}
            content="You haven't register work schedule."
            nameAlert="currentRegister"
          />
        )}
        {checkWorkSchedule?.hasMemberNotRegister
          ? location.pathname !== routePath.MEMBER_MANAGEMENT_PATH
            && Number(checkWorkSchedule?.hasMemberNotRegister) > 0 && (
              <AddWorkScheduleAlert
                hasAction
                content={`Your team has ${Number(
                  checkWorkSchedule.hasMemberNotRegister
                )} members who haven't register work schedule.`}
                onClickAlertAction={handleRedirectToManagerPage}
                nameAlert="hasMemberNotRegister"
                buttonItem={<EditIcon />}
              />
          )
          : ''}
      </div>
      <Footer />
    </>
  );
}
