// import * as actions from './actions';

const initialState = {
  holidayList: [],

};

export function holidayReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
