import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import timesheet from '../../asset/images/timesheet.svg';
import { drawerFeature } from '../../helper/constants';
import {
  useActiveGroup,
  useDrawerState,
  useLeftBarHover,
} from '../../provider/DrawerProvider';
import { userSelector } from '../../state/modules/user';
import { MenuItem } from './MenuItem';
import { BootstrapTooltip, Drawer, styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

export const LeftBarMenu = () => {
  const { permissions } = useSelector(userSelector);
  // eslint-disable-next-line max-len
  const allowDrawerFeature = drawerFeature.filter((menuItem) => permissions.includes(menuItem.permisstion));
  const { open, setOpen } = useDrawerState();
  const { activeGroup, setActiveGroup } = useActiveGroup();
  const { isHover, setIsHover } = useLeftBarHover();

  const handleSelectMenuItem = (item) => {
    if (!activeGroup.some((items) => items === item?.id)) {
      setActiveGroup((preState) => [...preState, item.id]);
    } else {
      setActiveGroup(activeGroup.filter((element) => element !== item.id));
    }
  };
  const classes = useStyles();
  const itemExpand = open ? (
    <ChevronRightIcon style={{ fontSize: 16 }} />
  ) : (
    <ChevronLeftIcon style={{ fontSize: 16 }} />
  );
  return (
    <div onMouseLeave={() => setIsHover(false)} className={classes?.root}>
      {(!open || isHover) && (
        <Box className={classes.boxBtn}>
          <BootstrapTooltip placement="right" title={`${open ? 'Collapse' : 'Expand'}`}>
            {open || isHover ? (
              <IconButton
                className={classes.buttonL}
                onClick={() => {
                  setOpen((preState) => !preState);
                  setIsHover(!open);
                  localStorage.setItem('stateLeftMenu', !open);
                }}
              >
                {itemExpand}
              </IconButton>
            ) : (
              <IconButton
                className={classes.buttonR}
                onClick={() => {
                  setOpen((preState) => !preState);
                  setIsHover(true);
                  localStorage.setItem('stateLeftMenu', !open);
                }}
              >
                {itemExpand}
              </IconButton>
            )}
          </BootstrapTooltip>
        </Box>
      )}
      <Box className={classes.container} onMouseEnter={() => setIsHover(true)}>
        <Drawer variant="permanent" open={open || isHover}>
          {open || isHover ? (
            <Box className={classes.box}>
              <ListItem className={classes.container}>
                <img src={timesheet} alt="VNCSS" className={classes.logoIcon} />
                <ListItemText>
                  <Typography className={classes.title}>TMS</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.description}
                  >
                    Timesheet Management
                  </Typography>
                </ListItemText>
              </ListItem>
              <Box>
                {(allowDrawerFeature || [])?.map((item) => (
                  <MenuItem
                    activeGroup={activeGroup}
                    onSelectItem={handleSelectMenuItem}
                    key={item.id}
                    item={item}
                    isHover={isHover}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box className={classes.box} />
          )}
        </Drawer>
      </Box>
    </div>
  );
};
