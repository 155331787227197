export const styles = {
  userInfo: {
    border: '1px solid #dadce0',
    borderRadius: 5,
    padding: '10px 8px',
    backgroundColor: '#dadce0',
  },
  userInfoContent: {
    fontWeight: 600,
  },
  textCapitalize: {
    textTransform: 'capitalize',
    fontWeight: 600,
  },
};
