import { Collapse, List } from '@material-ui/core';
import { shape, string } from 'prop-types';
import React from 'react';
import { MenuItem } from './MenuItem';

export const CollapseItem = ({ open, subItem }) => (
  <Collapse in={open} timeout="auto" unmountOnExit>
    <List
      component="div"
      disablePadding
      sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}
    >
      <div style={{ marginLeft: '20px' }}>
        {subItem.map((child) => (
          <MenuItem key={child.id} item={child} />
        ))}
      </div>
    </List>
  </Collapse>
);
CollapseItem.propTypes = {
  open: string.isRequired,
  subItem: shape.isRequired,
};
