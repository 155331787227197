import React, { useEffect } from 'react';
import { node } from 'prop-types';

const drawerContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [activeGroup, setActiveGroup] = React.useState([]);
  const [isHover, setIsHover] = React.useState(false);
  useEffect(() => {
    const stateLeftMenu = localStorage.getItem('stateLeftMenu');
    if (JSON.parse(stateLeftMenu)) {
      setOpen(true);
    }
  }, []);

  return (
    <drawerContext.Provider
      value={{
        open,
        setOpen,
        activeGroup,
        setActiveGroup,
        isHover,
        setIsHover,
      }}
    >
      {children}
    </drawerContext.Provider>
  );
};

export const useDrawerState = () => {
  const { open, setOpen } = React.useContext(drawerContext);
  return { open, setOpen };
};
export const useActiveGroup = () => {
  const { activeGroup, setActiveGroup } = React.useContext(drawerContext);
  return { activeGroup, setActiveGroup };
};
export const useLeftBarHover = () => {
  const { isHover, setIsHover } = React.useContext(drawerContext);
  return { isHover, setIsHover };
};
ViewportProvider.propTypes = {
  children: node.isRequired,
};

export default ViewportProvider;
