export const styles = {
  headerTable: {
    backgroundColor: '#000000c9',
    color: '#fff',
    fontWeight: 600,
    border: '1px solid #fff'
  },
  bodyTable: {
    border: '1px solid #dadce0',
    padding: 8
  },
  filterItem: {
    margin: '0px 5px'
  },
  actionButton: {
    color: '#000'
  }
};
