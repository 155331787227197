import {
  bool, func,
  shape, string
} from 'prop-types';
import React from 'react';
import { MultipleTab } from './MultipleTab';
import { SingleTab } from './SingleTab';

export const MenuItem = ({ item, onSelectItem, isHover }) => {
  const Component = item?.childs ? MultipleTab : SingleTab;
  return (
    <Component onSelectItem={onSelectItem} item={item} isHover={isHover} />
  );
};
MenuItem.propTypes = {
  item: shape({ root: string }).isRequired,
  onSelectItem: func.isRequired,
  isHover: bool.isRequired,
};
