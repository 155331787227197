/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import {
  Button, CircularProgress, Grid, Modal, Tooltip
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import {
  array, bool, func, string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RRule } from 'rrule';
import { showNotification } from '../../state/modules/notification/actions';
import { requestGetCurrentUser, userSelector } from '../../state/modules/user';
import {
  checkUserRegisterWorkerSchedule,
  createAllWorkSchedule,
} from '../../state/modules/workSchedule/actions';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({ ...styles(theme) }));

export default function AddWorkSchedule({
  open,
  listUser,
  onCloseModal,
  onFetchPage,
  currentSchedule,
}) {
  const [dayValue, setDayValue] = useState([]);
  const [errors, setErrors] = useState(false);
  const classes = useStyles();
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const DAYS = [
    {
      key: RRule.MO,
      icon: 'M',
      tooltipTitle: 'Monday',
      value: 0,
    },
    {
      key: RRule.TU,
      icon: 'T',
      tooltipTitle: 'Tuesday',
      value: 1,
    },
    {
      key: RRule.WE,
      icon: 'W',
      tooltipTitle: 'Wednesday',
      value: 2,
    },
    {
      key: RRule.TH,
      icon: 'T',
      tooltipTitle: 'Thursday',
      value: 3,
    },
    {
      key: RRule.FR,
      icon: 'F',
      tooltipTitle: 'Friday',
      value: 4,
    },
  ];
  useEffect(() => {
    if (currentSchedule && listUser?.length === 1) {
      const rrule = new RRule(RRule.parseString(currentSchedule));
      if (rrule) {
        const selectedDay = rrule?.options?.byweekday || [];
        setDayValue(
          selectedDay.map(
            (item) => DAYS.find((day) => item === day?.value)?.key
          )
        );
      }
    } else {
      setDayValue([]);
    }
    setErrors('');
  }, [open]);

  const handleSubmitForm = () => {
    if (user?.title === 'admin' && dayValue?.length === 0) {
      setErrors('You must choose at least 1 days of the week');
      return;
    }
    if (user?.title === 'manager' && dayValue?.length < 3) {
      setErrors('You must choose at least 3 days of the week');
      return;
    }
    setErrors('');
    // Create a rule:
    const rule = new RRule({
      byweekday: dayValue,
    });
    setIsLoading(true);
    dispatch(
      createAllWorkSchedule({
        payload: {
          schedule: rule?.toString(),
          userId: listUser,
          state: 'ACTIVE',
        },
        callback: (res) => {
          if (res && res.error) {
            dispatch(
              showNotification('failed', res.error.message || res.error, true)
            );
          } else {
            dispatch(
              showNotification('success', 'Update schedule successfully!')
            );
            dispatch(requestGetCurrentUser());
            dispatch(checkUserRegisterWorkerSchedule());
            onFetchPage();
            onCloseModal();
            setDayValue([]);
            setIsLoading(false);
          }
        },
      })
    );
  };
  const handleFormat = (event, newFormats) => {
    setDayValue(newFormats);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        <div className={classes.bodyModal} style={{ width: '30%' }}>
          <Grid container spacing={4}>
            <div className={classes.groupTitle}>
              {' '}
              Select weekday working offline schedule
            </div>
          </Grid>
          <Grid className={classes?.groupBtn} container spacing={2} lg={12}>
            <ToggleButtonGroup
              value={dayValue}
              fullWidth
              onChange={handleFormat}
              color="primary"
              aria-label="Platform"
            >
              {DAYS.map((item) => (
                <ToggleButton
                  className={classes?.dayOfWeekBtn}
                  color="primary"
                  value={item?.key}
                  aria-label="bold"
                  classes={{
                    selected: classes.bgBtn,
                  }}
                >
                  <Tooltip placement="bottom" title={item?.tooltipTitle || ''}>
                    <span>{item.icon}</span>
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Grid container justify="center" style={{ marginTop: 20 }}>
              <Grid item md={3} xs={3}>
                <Button
                  style={{ width: '80%' }}
                  variant="contained"
                  onClick={onCloseModal}
                  color="secondary"
                >
                  Close
                </Button>
              </Grid>
              <Grid item md={3} xs={3}>
                <Button
                  variant="contained"
                  style={{ width: '80%' }}
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmitForm}
                >
                  { loading ? <CircularProgress size={20} color="#fff" />
                    : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {errors && (
            <Grid className={classes.warningText} item md={10} xs={8}>
              *
              {errors}
              {' '}
            </Grid>
          )}
        </div>
      </Modal>
    </>
  );
}

AddWorkSchedule.propTypes = {
  open: bool.isRequired,
  listChecked: array.isRequired,
  setOpenPropsModal: func.isRequired,
  onFetchPage: func.isRequired,
  currentSchedule: string.isRequired,
};
