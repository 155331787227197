import { Button, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  bool, element, func, string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ConfirmAlert from '../ConfirmAlert';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

export default function AddWorkScheduleAlert({
  onClickAlertAction,
  content,
  hasAction,
  nameAlert,
  buttonItem,
}) {
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = useState({ [nameAlert]: false });

  const handleSaveAlertStatus = (alertType) => {
    const value = JSON.parse(localStorage.getItem('alert')) || {};
    value[nameAlert] = alertType;
    localStorage.setItem('alert', JSON.stringify(value));
    setOpen(value);
  };

  const handleSelectCloseAlertType = (alertType) => {
    if (alertType === 'temporary') {
      handleSaveAlertStatus(false);
    } else if (alertType === 'forever') {
      handleSaveAlertStatus('forever');
    }
    setOpenConfirmAlert(false);
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('alert')) || {};
    setOpen(value);
  }, []);
  return (
    <>
      <div className={classes.root}>
        <Collapse
          in={open?.[nameAlert] !== false && open?.[nameAlert] !== 'forever'}
        >
          <Alert
            severity="error"
            action={(
              <>
                {hasAction && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={onClickAlertAction}
                  >
                    {buttonItem}
                  </Button>
                )}
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenConfirmAlert(true);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </Button>
              </>
            )}
          >
            {content}
          </Alert>
        </Collapse>
        <ConfirmAlert
          isOpen={openConfirmAlert}
          onOpenPropsModal={() => setOpenConfirmAlert(false)}
          onSelectTypeCloseAlert={handleSelectCloseAlertType}
        />
      </div>
    </>
  );
}
AddWorkScheduleAlert.propTypes = {
  onClickAlertAction: func,
  content: string,
  hasAction: bool,
  nameAlert: string.isRequired,
  buttonItem: element.isRequired,
};

AddWorkScheduleAlert.defaultProps = {
  onClickAlertAction: () => {},
  content: null,
  hasAction: false,
};
