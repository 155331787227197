import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Logo from '../../asset/images/unsupportedMobile.png';

const useStyles = makeStyles({
  ...styles,
});

function UnsupportedMobile() {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justify="center"
      alignItems="center"
      style={{ padding: 30 }}
    >
      <img
        alt="Unsupported mobile"
        src={Logo}
        style={{ height: '40%', width: '60%' }}
      />
      <Grid className={classes?.fontHead}>Mobile Unsupported!</Grid>
      <Grid className={classes?.fontTitle}>
        Sorry but we don&apos;t support access to the application by phone.
      </Grid>
      <Grid className={classes?.fontTitle}>
        You can try using a browser on your computer or contact the admin for
        support.
      </Grid>
    </Grid>
  );
}

export default UnsupportedMobile;
