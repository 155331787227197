export const styles = {
  fontTitle: {
    fontFamily: 'monospace',
    padding: 20,
    fontSize: 16,
    textAlign: 'center'
  },
  fontHead: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
    padding: 10,
    fontFamily: 'monospace',

  }
};
