export const styles = {
  footer: {
    fontSize: '12px',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    fontFamily: '"Inter",sans-serif',
    fontWeight: 500,
    lineHeight: '1.45',
    height: '60px',
    backgroundColor: 'white'
  },
};
