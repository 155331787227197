import { styled } from '@material-ui/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import { Tooltip } from '@material-ui/core';
import React from 'react';

export const styles = () => ({
  container: {
    position: 'relative',
    zIndex: 'fab',
    marginTop: '15px',
  },
  buttonR: {
    width: '16px',
    height: '16px',
    position: 'fixed',
    zIndex: 1350,
    left: 'calc(var(--shrink) - 12px)',
    top: 90,
    boxShadow:
      'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
    backgroundColor: '#fff',
    transition: 'left 0.3s ease',
    transform: 'rotate(180deg)',
    '&:hover': {
      backgroundColor: '#2684FF',
      color: '#fff',
    },
  },
  buttonL: {
    width: '16px',
    height: '16px',
    position: 'fixed',
    zIndex: 1350,
    left: 'calc(var(--expand) - 12px)',
    top: 90,
    boxShadow:
      'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
    transition: 'left 0.3s ease',
    backgroundColor: '#fff',
    transform: 'rotate(180deg)',
    '&:hover': {
      backgroundColor: '#2684FF',
      color: '#fff',
    },
  },
  box: {
    paddingX: 2,
    paddingTop: 3,
    marginTop: '50px',
  },
  textSingleLevel: {
    fontWeight: 700,
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  itemIcon: {
    width: '24px',
    height: '24px',
    color: '#42526E',
  },
  boxBtn: {
    zIndex: 'app bar',
    position: 'relative',
    transition: 'left 0.2s ease',
  },
  logoIcon: {
    width: 35,
    height: 35,
    marginTop: 2,
    color: '#0065FF',
    padding: 10,
  },
  smallLogoIcon: {
    width: 30,
    height: 30,
    marginTop: 2,
    color: '#0065FF',
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily:
      "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;",
  },
  description: {
    display: 'inline',
    fontSize: 12,
    color: '#6B778C',
    fontFamily:
      "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;",
  },
  itemMenuIcon: {
    color: '#42526E',
    width: 24,
    height: 24,
    paddingRight: '5px',
  },
  singleItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: '27px',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-selected': {
      color: '#1871E8',
      backgroundColor: '#F0F2F5',
      '& .MuiListItemIcon-root': {
        color: '#1871E8',
      },
    },
    '&:hover': {
      backgroundColor: '#F0F2F5',
      color: '#1871E8',
      '& .MuiListItemIcon-root': {
        color: '#1871E8',
      },
    },
  },
  multipleItem: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: '27px',
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export const text = {
  fontSize: '14px',
  color: '#42526E',
  fontFamily:
    'BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-sans',
  '&:hover': {
    color: '#1871E8',
  },
  fontStyle: 'normal',
  fontWeight: 400,
};

export const textMulti = {
  fontWeight: 700,
  fontSize: '11px',
  textTransform: 'uppercase',
  fontFamily:
    'BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-sans',
  color: '#42526E',
  '&:hover': {
    color: '#1871E8',
  },
};

const openedMixin = () => ({
  width: 'var(--expand)',
  overflowX: 'hidden',
});

const closedMixin = () => ({
  width: 'var(--shrink)',
  overflowX: 'hidden',
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  transition: 'width 0.2s ease',
  background: 'red',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '& .MuiPaper-root': {
    transition: 'width 0.2s ease',
  },
  '& .MuiListItem-root': {
    width: '96%',
    borderRadius: 6,
  },
}));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  '& .MuiTooltip-tooltip': {
    color: 'white',
    backgroundColor: 'black',
    fontFamily:
    'BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-sans',
    fontSize: '12px'

  }
}));
