/* eslint-disable react/forbid-prop-types */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

export default function ConfirmAlert({
  isOpen,
  onOpenPropsModal,
  onSelectTypeCloseAlert,
}) {
  const classes = useStyles();

  const [permanently, setPermanently] = useState(false);
  const handleTurnOff = () => {
    onSelectTypeCloseAlert(permanently ? 'forever' : 'temporary');
  };

  return (
    <>
      <Dialog
        onClose={onOpenPropsModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={onOpenPropsModal}>
          <span className={classes?.textTitle}>Turn off the notification?</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={classes?.textTitle}>
              You&apos;re turning off an important notification. Are you sure
              about that?
            </span>
          </DialogContentText>
          <FormControlLabel
            control={(
              <Checkbox
                checked={permanently}
                onChange={() => {
                  setPermanently((current) => !current);
                }}
                name="permanently"
                color="primary"
              />
            )}
            label="Permanently"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTurnOff} color="primary">
            Turn off
          </Button>
          <Button autoFocus onClick={onOpenPropsModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmAlert.propTypes = {
  isOpen: bool.isRequired,
  onOpenPropsModal: func,
  onSelectTypeCloseAlert: func,
};

ConfirmAlert.defaultProps = {
  onOpenPropsModal: () => { },
  onSelectTypeCloseAlert: () => {},
};
