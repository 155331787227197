import MomentUtils from '@date-io/moment';
import {
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RateReviewIcon from '@material-ui/icons/RateReview';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { bool } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from '../../state/modules/config/selector';
import { showNotification } from '../../state/modules/notification/actions';
import {
  requestGetAllRequestsByAdmin,
  requestPutRequestsCompensation, requestPutRequestsForget,
  reviewMultipleRequest
} from '../../state/modules/requests/actions';
import { statusLeaves } from '../../state/modules/requests/reducer';
import { userSelector } from '../../state/modules/user/selector';
import CustomTable from '../CustomTable';
import { FilterComponent } from '../FilterComponent';
import ManagerListComponent from '../ManagerListComponent';
import RequestStatusComponent from '../RequestStatusComponent';
import ConfirmReviewJustification from './ConfirmReviewJustification';

import { styles } from './styles';
import CommentMultipleModalComponent from '../ConfirmRequestComponent/CommentMultipleModalComponent';

const originalForm = {
  fromDate: moment().startOf('month'),
  toDate: moment().endOf('month')
};

const useStyles = makeStyles({
  ...styles
});

const listChip = ['managerId', 'status', 'badgeNumber', 'name'];

export default function ReviewJustificationComponent({ fetchFlag }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rowPerPage } = useSelector(configSelector);
  const { user } = useSelector(userSelector);
  const [formValue, setFormValue] = useState(originalForm);
  const [dataJustification, setDataJustification] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openModal, setOpenModal] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [openMultipleModal, setOpenMultipleModal] = useState(false);
  const handleFetchJustification = (dataValue, page) => {
    setSelectedIds([]);
    if (!dataValue.fromDate || !dataValue.toDate) return;
    const filter = {
      requestTypeIds: [
        '9c796106-d223-4ced-bb0c-7b0467bbc1f8',
        '187f0f75-3394-4962-92af-6efc131f8e74'
      ],
      fromDate: dataValue.fromDate.toISOString(),
      toDate: dataValue.toDate.toISOString(),
    };
    if (dataValue.managerId) filter.managerId = dataValue.managerId.value;
    if (dataValue.status) filter.status = dataValue.status;
    if (dataValue.badgeNumber) filter.badgeNumber = dataValue.badgeNumber.trim();
    if (dataValue.name) filter.name = dataValue.name.trim();
    if (dataValue.fromDate.valueOf() <= dataValue.toDate.valueOf()) {
      dispatch(requestGetAllRequestsByAdmin({
        filter: {
          ...filter,
          limit: rowPerPage,
          page: page || 1
        },
        callback: (res) => {
          if (res && res.error) {
            dispatch(showNotification('failed', res.error.message || res.error, true));
          } else {
            setDataJustification(res.data);
            if (res.pagination) {
              setPagination({
                current: res.pagination.page,
                total: res.pagination.count,
                count: Math.ceil(res.pagination.count / rowPerPage)
              });
            }
          }
        }
      }));
    }
  };

  useEffect(() => {
    if (fetchFlag) {
      handleFetchJustification(originalForm);
    }
  }, [fetchFlag]);

  const handleChangeTable = (e, page) => {
    handleFetchJustification(formValue, page);
  };

  const handleFormChange = (type) => (event) => {
    const dataChange = {
      ...formValue,
      [type]: type.toLowerCase().includes('date') ? moment(event) : event.target.value
    };
    if (type !== 'badgeNumber') {
      handleFetchJustification(dataChange);
    }
    setFormValue(dataChange);
  };

  const handleKeyDownFilter = (event) => {
    if (event.keyCode === 13) {
      handleFetchJustification(formValue, 1);
    }
  };

  const handleDeleteItemFilter = (type) => () => {
    const dataChange = {
      ...formValue,
      [type]: ''
    };
    handleFetchJustification(dataChange);
    setFormValue(dataChange);
  };

  const handleConfirmReviewRequest = (data) => {
    if (data.requestTypeId === '187f0f75-3394-4962-92af-6efc131f8e74') {
      dispatch(requestPutRequestsCompensation({
        id: data.id,
        payload: {
          confirmBy: user.id,
          status: data.status,
          adminComment: data.adminComment
        },
        callback: (res) => {
          if (res.error) {
            dispatch(showNotification('failed', res.error.message || res.error, true));
            return;
          }
          handleFetchJustification(formValue, pagination.current);
        }
      }));
    } else {
      dispatch(requestPutRequestsForget({
        id: data.id,
        payload: {
          confirmBy: user.id,
          status: data.status,
          adminComment: data.adminComment
        },
        callback: (res) => {
          if (res.error) {
            dispatch(showNotification('failed', res.error.message || res.error, true));
            return;
          }
          handleFetchJustification(formValue, pagination.current);
        }
      }));
    }
  };

  const handleReviewRequest = (data) => () => {
    setOpenModal(data);
  };

  const handleCheckAll = () => {
    if (selectedIds.length > 0) {
      return setSelectedIds([]);
    }
    const selectableIds = dataJustification.filter(item => (item.status === statusLeaves.CONFIRMED)).map(item => item.id)
    setSelectedIds(selectableIds);

  }

  const handleClickCheckBox = (e) => {
    const { id } = e.target;
    if (selectedIds.includes(id)) {
      return setSelectedIds((current) => current.filter(selectedItem => selectedItem !== id))
    }
    return setSelectedIds([...selectedIds, id])
  }

  const checkedAll = useMemo(() => {
    if (dataJustification.length === 0) return false;
    const notMatch = dataJustification.filter((item) => !selectedIds.includes(item.id))
    return !notMatch.length;
  }, [dataJustification, selectedIds]);

  const handleClickReviewAllButton = () => {
    setOpenMultipleModal(true);
  }

  const handleSubmitMultipleRequest = ({ selectedIds, comment, status }) => {
    dispatch(reviewMultipleRequest({
      ids: selectedIds,
      adminComment: comment,
      status,
      callback: () => {
        handleFetchJustification(formValue, pagination.current);
        setSelectedIds([])
      }
    }))
  }

  const columns = [
    {
      label: (
        <Checkbox
          indeterminate={selectedIds.length < dataJustification.length && selectedIds.length > 0}
          checked={checkedAll}
          className=""
          onChange={handleCheckAll}
        />
      ),
      field: 'select',
      render: (_, __, row) => (
        <Tooltip title={row.badgeNumber}>
          <Checkbox
            id={row.id}
            checked={selectedIds.includes(`${row.id}`)}
            onChange={handleClickCheckBox}
            color="primary"
            disabled={(row.status !== statusLeaves.CONFIRMED)}
          />
        </Tooltip>
      ),
    },
    {
      field: 'badgeNumber',
      label: 'ID',
    },
    {
      field: 'name',
      label: 'Member',
    },
    {
      field: 'requestDate',
      label: 'Request Date',
      render: (record) => <>{record && moment(record).format('DD/MM/YYYY')}</>
    },
    {
      field: 'requestTypeName',
      label: 'Type',
    },
    {
      field: 'compensationDate',
      label: 'Comp Date',
      render: (record) => <>{record && moment(record).format('DD/MM/YYYY')}</>

    },

    {
      field: 'errorCount',
      label: 'Error',
      render: (text) => (text ? '1' : '0')

    },
    {
      field: 'comment',
      label: 'Note'
    },
    {
      field: 'managerName',
      label: 'Manager Name'
    },
    {
      field: 'approveByName',
      label: 'Confirm By'
    },
    {
      field: 'managerComment',
      label: 'Manager Comment'
    },
    {
      field: 'confirmByName',
      label: 'Review By'
    },
    {
      field: 'adminComment',
      label: 'Admin Comment'
    },
    {
      field: 'status',
      label: 'Status',
      render: (record) => {
        switch (record) {
          case statusLeaves.QUEUE:
            return (
              <Chip style={{ backgroundColor: '#1976D2', color: '#fff', textTransform: 'uppercase' }} label={record} />
            );
          case statusLeaves.APPROVED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} color="primary" />
            );
          case statusLeaves.REJECTED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} />
            );
          case statusLeaves.CONFIRMED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} color="primary" />
            );
          default:
            return null;
        }
      }

    },
    {
      field: '#',
      label: 'Action',
      render: (record, index, row) => (
        <>
          {!row.confirmBy && (
            <>
              <Tooltip title="Review">
                <CheckCircleOutlineIcon
                  style={{ cursor: 'pointer' }}
                  onClick={handleReviewRequest(row)}
                />
              </Tooltip>
            </>
          )}

        </>
      )
    },
  ];
  return (
    <>
      <Grid container>
        <FilterComponent
          listFilterRender={(
            <>
              <Chip
                label={`From ${formValue.fromDate.format('DD/MM/YYYY')} to ${formValue.toDate.format('DD/MM/YYYY')}`}
                className={classes.filterItem}
              />
              {
                listChip.map((item) => (formValue[item]
                  ? (
                    <Chip
                      key={item}
                      label={formValue[item].label || formValue[item]}
                      className={classes.filterItem}
                      onDelete={handleDeleteItemFilter(item)}
                    />
                  )
                  : null))
              }
            </>
          )}

          formFilter={(
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="badgeNumber"
                      label="ID"
                      style={{ width: '100%' }}
                      value={formValue.badgeNumber}
                      onChange={handleFormChange('badgeNumber')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={handleKeyDownFilter}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="name"
                      label="Name"
                      style={{ width: '100%' }}
                      value={formValue.name}
                      onChange={handleFormChange('name')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={handleKeyDownFilter}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ManagerListComponent
                      value={formValue.managerId}
                      onChange={handleFormChange('managerId')}
                      displayEmpty
                      style={{ width: '100%' }}
                      labelInValue
                      inputLabel="Manager"
                    />

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequestStatusComponent
                      value={formValue.status}
                      onChange={handleFormChange('status')}
                    />

                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="from-date-picker"
                      label="From Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      value={formValue.fromDate}
                      onChange={handleFormChange('fromDate')}
                    />

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="to-date-picker"
                      label="To Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      value={formValue.toDate}
                      onChange={handleFormChange('toDate')}
                    />

                  </Grid>

                </Grid>
              </MuiPickersUtilsProvider>

            </>
          )}
          actions={(
            <Button
              variant='outlined'
              color='primary'
              disabled={selectedIds.length === 0}
              startIcon={<RateReviewIcon />}
              onClick={handleClickReviewAllButton}
            >
              Review All {selectedIds.length > 0 ? `(${selectedIds.length})` : ''}
            </Button>
          )}
        />

      </Grid>
      <CustomTable
        dataSource={dataJustification}
        columns={columns}
        classnameHeaderCell={classes.headerTable}
        classnameBodyCell={classes.bodyTable}
        pagination={pagination}
        onChange={handleChangeTable}
      />
      <ConfirmReviewJustification
        openProps={openModal}
        handleSendRequest={handleConfirmReviewRequest}
        setOpenProps={setOpenModal}
      />

      {openMultipleModal && (
        <CommentMultipleModalComponent
          open
          onClose={() => setOpenMultipleModal(false)}
          requests={dataJustification}
          selectedIds={selectedIds}
          onSubmit={handleSubmitMultipleRequest}
          isForAdmin
        />
      )}

    </>
  );
}

ReviewJustificationComponent.propTypes = {
  fetchFlag: bool
};

ReviewJustificationComponent.defaultProps = {
  fetchFlag: false
};
