export const styles = {
  headerTable: {
    backgroundColor: '#000000c9',
    color: '#fff',
    fontWeight: 600,
    border: '1px solid #fff'
  },
  bodyTable: {
    border: '1px solid #dadce0',
    padding: 0
  },
  lateItem: {
    color: '#8f0a0c',
    fontWeight: 600
  },
  earlyItem: {
    backgroundColor: '#e5e7e8',
    color: '#0a4d88',
    fontWeight: 600
  },
  requestItem: {
    color: '#0a4d88',
    fontWeight: 600
  },
  lackItem: {
    backgroundColor: '#faebd7',
  },
  compItem: {
    // backgroundColor: '#f3dab9',
    fontWeight: 600
  },
  filterItem: {
    margin: '0px 5px'
  },
  paddingCell: {
    padding: 8,
    border: '1px solid #dadce0',
  },
  actionIcon: {
    cursor: 'pointer',
    marginLeft: 5
  },
  leaveItem: {
    content: '',
    backgroundColor: '#c7c5c552'
  },
  paddingCellNone: {
    padding: 0,
    border: '1px solid #dadce0',
  },
  leaveExplain: {
    backgroundColor: '#c7c5c552',
    width: 30,
    height: 20,
    border: '1px solid #dadce0'
  },
  iconWarning: {
    color: 'red'
  }
};
