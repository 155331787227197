import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RRule, datetime } from 'rrule';
import { userSelector } from '../../state/modules/user';
import { useStyles } from './styles';

export default function WorkFromHomeModal({
  open,
  onClose,
  startWorkingTime,
  comment,
  onCheckIn,
  onDiscard,
  closeOnAction,
}) {
  const classes = useStyles();
  /* --------------------------------- States/Vars --------------------------------- */
  const [currentComment, setCurrentComment] = useState('');
  const hasComment = !_.isNil(comment); // Check if the props 'comment' already has a value
  /* -------------------------------- Handlers -------------------------------- */
  const { user } = useSelector(userSelector);
  const [validTimekeeping, setValidTimekeeping] = useState(true);
  const handleCheckIn = () => {
    onCheckIn(currentComment);
    closeOnAction && onClose();
  };
  const handleDiscard = () => {
    onDiscard();
    setCurrentComment(null);
    closeOnAction && onClose();
  };
  useEffect(() => {
    if (user?.schedule) {
      const rruleSet = new RRule(RRule.parseString(user?.schedule));
      const currentDate = new Date();
      const listSchedule = rruleSet.between(
        datetime(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        ),
        datetime(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate() + 1
        )
      );
      if (listSchedule?.length > 0) {
        setValidTimekeeping(false);
      }
    }
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        classes={{ paper: classes.container }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          <Typography variant="h5">Work from home - Check in online</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {/* Start working time */}
          <DialogContentText className={classes.label1}>
            Start working time
          </DialogContentText>
          <TextField
            style={{ marginTop: 0 }}
            // InputProps={{ classes: { input: classes.input1 } }}
            classes={{ root: classes.input1 }}
            size="medium"
            disabled
            margin="dense"
            id="name"
            type="text"
            variant="outlined"
            value={startWorkingTime}
            fullWidth
          />

          {/* Comment */}
          <Typography className={classes.label2}>Comment</Typography>
          <TextField
            onChange={(e) => setCurrentComment(e.target.value)}
            classes={{ root: classes.input2 }}
            multiline
            rows={8}
            rowsMax={8}
            margin="dense"
            id="name"
            type="text"
            variant="outlined"
            placeholder={hasComment ? '' : 'Work from home note or excuse'}
            fullWidth
            disabled={hasComment}
            value={hasComment ? comment : currentComment}
            autoFocus
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleDiscard}
            color="primary"
            variant={hasComment ? 'contained' : 'text'}
            size="large"
          >
            {hasComment ? 'Close' : 'Discard'}
          </Button>
          {!hasComment && (
            <Button
              onClick={handleCheckIn}
              color="primary"
              variant="contained"
              size="large"
            >
              Check In
            </Button>
          )}
        </DialogActions>
        {!validTimekeeping && !hasComment && (
          <Grid className={classes.warningText} item md={11} xs={8}>
            {`*Today's you have to work at the company, if you check in online you
            will only get 80% of your salary today!`}
          </Grid>
        )}
      </Dialog>
    </div>
  );
}

WorkFromHomeModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  startWorkingTime: string.isRequired,
  comment: string,
  onCheckIn: func.isRequired,
  onDiscard: func,
  closeOnAction: bool,
};

WorkFromHomeModal.defaultProps = {
  closeOnAction: true,
  onDiscard: () => null,
  comment: null,
};
