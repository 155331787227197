import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    borderRadius: 3,
    minWidth: 600,
  },
  title: {
    padding: '24px 24px 16px 24px',
  },
  content: {
    padding: '0 24px 16px 24px',
  },
  actions: {
    padding: '0 24px 24px 24px',
  },
  input1: {
    '& input': {
      height: 20,
      background: '#EBECF0',
      borderRadius: 3,
      padding: '10px 8px',
    },
    '& fieldset': {
      border: 0,
    },
  },
  label1: {
    marginBottom: 0,
    paddingBottom: 2,
    fontSize: 12,
  },
  label2: {
    fontSize: 14,
    marginTop: 16,
    paddingBottom: 2,
  },
  input2: {
    background: '#FAFBFC',
    marginTop: 0,
    '& .MuiInputBase-root': {
      padding: 0,
    },
    '& textarea': {
      padding: 8,
    },
    '& fieldset': {
      border: '2px solid #DFE1E6',
      borderRadius: 3,
    },
  },
  warningText: {
    color: 'red',
    margin: '10px 5px 10px 15px',
  },
});
