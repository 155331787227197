import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { shape, string } from 'prop-types';
import { styles, text } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));
export const SingleTab = ({ item }) => {
  const { pathname } = window.location;
  const classes = useStyles();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(false);
  const handleClick = () => {
    if (!selectedIndex) {
      setSelectedIndex(true);
    } else {
      setSelectedIndex(false);
    }
  };

  return (
    <ListItem
      href={item.routePath}
      button
      selected={selectedIndex || pathname === item?.routePath}
      onClick={() => {
        handleClick();
        history.push(item.routePath);
      }}
      className={classes.singleItem}
    >
      <>
        <ListItemIcon className={classes.itemMenuIcon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.label}
          primaryTypographyProps={{ style: text }}
        />
      </>
    </ListItem>
  );
};
SingleTab.propTypes = {
  item: shape({ root: string }).isRequired,
};
