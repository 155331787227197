import MomentUtils from '@date-io/moment';
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RateReviewIcon from '@material-ui/icons/RateReview';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { bool } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from '../../state/modules/config/selector';
import { showNotification } from '../../state/modules/notification/actions';
import {
  requestGetRequestsForManager,
  requestPutRequestsCompensation,
  requestPutRequestsForget,
  reviewMultipleRequest
} from '../../state/modules/requests/actions';
import { statusLeaves } from '../../state/modules/requests/reducer';
import { userSelector } from '../../state/modules/user/selector';
import CustomTable from '../CustomTable';
import { FilterComponent } from '../FilterComponent';
import RequestStatusComponent from '../RequestStatusComponent';
import CommentModalComponent from './CommentModalComponent';
import CommentMultipleModalComponent from './CommentMultipleModalComponent';
import { styles } from './styles';

const originalForm = {
  fromDate: moment().startOf('month'),
  toDate: moment().endOf('month'),
  myRequest: false,
};

const listChip = ['status', 'badgeNumber'];

const useStyles = makeStyles({
  ...styles
});

export function ConfirmJustificationComponent({ fetchFlag }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rowPerPage } = useSelector(configSelector);
  const { user } = useSelector(userSelector);
  const [formValue, setFormValue] = useState(originalForm);
  const [dataJustification, setDataJustification] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [openModal, setOpenModal] = useState({});
  const [openMultipleModal, setOpenMultipleModal] = useState(false);
  const handleFetchJustification = (dataValue, page) => {
    if (!dataValue.fromDate || !dataValue.toDate) return;
    const filter = {
      requestTypeIds: [
        '9c796106-d223-4ced-bb0c-7b0467bbc1f8',
        '187f0f75-3394-4962-92af-6efc131f8e74'
      ],
      fromDate: dataValue.fromDate.format('MM/DD/YYYY'),
      toDate: dataValue.toDate.format('MM/DD/YYYY'),
    };
    if (dataValue.status) filter.status = dataValue.status;
    if (dataValue.badgeNumber) filter.badgeNumber = dataValue.badgeNumber;
    if (dataValue.myRequest) filter.managerOnly = dataValue.myRequest

    if (dataValue.fromDate.valueOf() <= dataValue.toDate.valueOf()) {
      dispatch(requestGetRequestsForManager({
        filter: {
          ...filter,
          limit: rowPerPage,
          page: page || 1
        },
        callback: (res) => {
          if (res && res.error) {
            dispatch(showNotification('failed', res.error.message || res.error, true));
          } else {
            setDataJustification(res.data);
            if (res.pagination) {
              setPagination({
                current: res.pagination.page,
                total: res.pagination.count,
                count: Math.ceil(res.pagination.count / rowPerPage)
              });
            }
          }
        }
      }));
    }
  };

  useEffect(() => {
    if (fetchFlag) {
      handleFetchJustification(originalForm);
    }
  }, [fetchFlag]);

  const handleChangeTable = (e, page) => {
    setSelectedIds([])
    handleFetchJustification(formValue, page);
  };

  const handleKeyDownFilter = (event) => {
    if (event.keyCode === 13) {
      handleFetchJustification(formValue, 1);
    }
    setSelectedIds([]);
  };

  const handleDeleteItemFilter = (type) => () => {
    const dataChange = {
      ...formValue,
      [type]: ''
    };
    handleFetchJustification(dataChange);
    setFormValue(dataChange);
    setSelectedIds([]);
  };

  const handleFormChange = (type) => (event) => {
    const dataChange = {
      ...formValue,
      [type]: type.toLowerCase().includes('date') ? moment(event) : (type !== 'myRequest' ? event.target.value : event.target.checked)
    };
    handleFetchJustification(dataChange);
    setFormValue(dataChange);
    setSelectedIds([]);
  };

  const handleRemoveManagerOnly = () => {
    const dataChange = {
      ...formValue,
      myRequest: false
    };
    setFormValue(dataChange);
    handleFetchJustification(dataChange);
  }

  const handleOpenModalComment = (data,) => () => {
    // setDataConfirm({
    //   ...data,
    //   status: type,
    //   approveBy: user.id
    // });
    setOpenModal(data);
  };

  const handleConfirmRequest = (data) => {
    if (data.requestTypeId === '187f0f75-3394-4962-92af-6efc131f8e74') {
      dispatch(requestPutRequestsCompensation({
        id: data.id,
        payload: {
          status: data.status,
          approveBy: user.id,
          managerComment: data.managerComment,
        },
        callback: () => {
          handleFetchJustification(formValue, pagination.current);
        }
      }));
    } else {
      dispatch(requestPutRequestsForget({
        id: data.id,
        payload: {
          status: data.status,
          approveBy: user.id,
          managerComment: data.managerComment,
        },
        callback: () => {
          handleFetchJustification(formValue, pagination.current);
        }
      }));
    }
  };

  const handleCheckAll = () => {
    if (selectedIds.length > 0) {
      return setSelectedIds([]);
    }
    const selectableIds = dataJustification.filter(item => (item.status === statusLeaves.QUEUE && item.managerId === user.id)).map(item => item.id)
    setSelectedIds(selectableIds);

  }

  const handleClickCheckBox = (e) => {
    const { id } = e.target;
    if (selectedIds.includes(id)) {
      return setSelectedIds((current) => current.filter(selectedItem => selectedItem !== id))
    }
    return setSelectedIds([...selectedIds, id])
  }

  const checkedAll = useMemo(() => {
    if (dataJustification.length === 0) return false;
    const notMatch = dataJustification.filter((item) => !selectedIds.includes(item.id))
    return !notMatch.length;
  }, [dataJustification, selectedIds]);

  const handleClickReviewAllButton = () => {
    setOpenMultipleModal(true);
  }

  const handleSubmitMultipleRequest = ({ selectedIds, comment, status }) => {
    dispatch(reviewMultipleRequest({
      ids: selectedIds,
      managerComment: comment,
      status,
      callback: () => {
        handleFetchJustification(formValue, pagination.current);
        setSelectedIds([])
      }
    }))
  }

  const columns = [
    {
      label: (
        <Checkbox
          indeterminate={selectedIds.length < dataJustification.length && selectedIds.length > 0}
          checked={checkedAll}
          className=""
          onChange={handleCheckAll}
        />
      ),
      field: 'select',
      render: (_, __, row) => (
        <Tooltip title={row.managerId !== user.id ? 'You\'re Line Manager. Only Manager can review it' : row.badgeNumber}>
          {!((row.status !== statusLeaves.QUEUE) || (row.managerId !== user.id)) ? (
            <Checkbox
              id={row.id}
              checked={selectedIds.includes(`${row.id}`)}
              onChange={handleClickCheckBox}
              color="primary"
            />) : <div>-</div>}
        </Tooltip>
      ),
    },
    {
      field: 'badgeNumber',
      label: 'ID',
    },
    {
      field: 'name',
      label: 'Member',
    },
    {
      field: 'requestDate',
      label: 'Request Date',
      render: (record) => <>{record && moment(record).format('DD/MM/YYYY')}</>
    },
    {
      field: 'requestTypeName',
      label: 'Type',
    },
    {
      field: 'compensationDate',
      label: 'Comp Date',
      render: (record) => <>{record && moment(record).format('DD/MM/YYYY')}</>

    },

    {
      field: 'errorCount',
      label: 'Error',
      render: (text) => (text ? '1' : '0')

    },
    {
      field: 'comment',
      label: 'Note',
    },
    {
      field: 'approveName',
      label: 'Confirm By'
    },
    {
      field: 'managerComment',
      label: 'Manager Comment'
    },
    {
      field: 'confirmByName',
      label: 'Review By'
    },
    {
      field: 'adminComment',
      label: 'Admin Comment'
    },
    {
      field: 'status',
      label: 'Status',
      render: (record) => {
        switch (record) {
          case statusLeaves.QUEUE:
            return (
              <Chip style={{ backgroundColor: '#1976D2', color: '#fff', textTransform: 'uppercase' }} label={record} />
            );
          case statusLeaves.APPROVED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} color="primary" />
            );
          case statusLeaves.REJECTED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} />
            );
          case statusLeaves.CONFIRMED:
            return (
              <Chip label={record} style={{ textTransform: 'uppercase' }} color="primary" />
            );
          default:
            return null;
        }
      }

    },
    {
      field: '#',
      label: 'Line Manager',
      render: (record, index, row) => (
        <>
          {row.lineManagerId === user.id ? 'Yes' : 'No'}
        </>
      )
    },
    {
      field: '#',
      label: 'Action',
      render: (record, index, row) => (
        <>
          {row.status === statusLeaves.QUEUE && row.managerId === user.id && (
            <IconButton
              disabled={row.managerId !== user.id}
              onClick={handleOpenModalComment(row, statusLeaves.APPROVED)}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          )}
        </>
      )
    },
  ];
  return (
    <>
      <Grid container>
        <FilterComponent
          listFilterRender={(
            <>
              <Chip
                label={`From ${formValue.fromDate.format('DD/MM/YYYY')} to ${formValue.toDate.format('DD/MM/YYYY')}`}
                className={classes.filterItem}
              />
              {
                listChip.map((item) => formValue[item] && (
                  <Chip
                    label={formValue[item].label || formValue[item]}
                    className={classes.filterItem}
                    onDelete={handleDeleteItemFilter(item)}
                  />
                ))
              }
              {formValue.myRequest && (<Chip
                label={"Pending your approval"}
                className={classes.filterItem}
                onDelete={handleRemoveManagerOnly}
              />)}
            </>
          )}
          formFilter={(
            <>
              <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker-inline"
                      label="From Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      value={formValue.fromDate}
                      onChange={handleFormChange('fromDate')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker-inline"
                      label="To Date"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      value={formValue.toDate}
                      onChange={handleFormChange('toDate')}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="date-picker-inline"
                      label="ID"
                      style={{ width: '100%' }}
                      value={formValue.badgeNumber}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleFormChange('badgeNumber')}
                      onKeyDown={handleKeyDownFilter}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RequestStatusComponent
                      value={formValue.status}
                      onChange={handleFormChange('status')}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={<Checkbox
                        checked={formValue.myRequest}
                        onChange={handleFormChange('myRequest')}
                        title='Pending your approval'
                      />}
                      label="Pending your approval"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          )}
          actions={(
            <Button
              variant='outlined'
              color='primary'
              disabled={selectedIds.length === 0}
              startIcon={<RateReviewIcon />}
              onClick={handleClickReviewAllButton}
            >
              Review All {selectedIds.length > 0 ? `(${selectedIds.length})` : ''}
            </Button>
          )}
        />
      </Grid>
      <CustomTable
        dataSource={dataJustification}
        columns={columns}
        classnameHeaderCell={classes.headerTable}
        classnameBodyCell={classes.bodyTable}
        pagination={pagination}
        onChange={handleChangeTable}
      />

      <CommentModalComponent
        openProps={openModal}
        setOpenProps={setOpenModal}
        handleSendRequest={handleConfirmRequest}
        commentOnly
      />
      {openMultipleModal && (
        <CommentMultipleModalComponent
          open
          onClose={() => setOpenMultipleModal(false)}
          requests={dataJustification}
          selectedIds={selectedIds}
          onSubmit={handleSubmitMultipleRequest}
        />
      )}
    </>
  );
}

ConfirmJustificationComponent.propTypes = {
  fetchFlag: bool
};

ConfirmJustificationComponent.defaultProps = {
  fetchFlag: false
};
