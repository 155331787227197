export const styles = (theme) => ({
  headerTable: {
    backgroundColor: '#000000c9',
    color: '#fff',
    fontWeight: 600,
    border: '1px solid #fff'
  },
  bodyTable: {
    border: '1px solid #dadce0',
    padding: 8
  },
  filterItem: {
    margin: '0px 5px'
  },
  filterTitle: {
    padding: '20px 0px',
    fontWeight: 600,
    boxSizing: 'border-box'
  },
  typography: {
    padding: theme.spacing(2),
  },
});
