import {
  Box, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  func, shape, string
} from 'prop-types';
import React, { useEffect } from 'react';
import { useActiveGroup } from '../../provider/DrawerProvider';
import { CollapseItem } from './CollapseItem';
import { styles, textMulti } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

export const MultipleTab = ({ item, onSelectItem }) => {
  const { activeGroup, setActiveGroup } = useActiveGroup();
  const { pathname } = window.location;
  const isExpand = item?.childs.some((items) => items.routePath === pathname);
  useEffect(() => {
    if (isExpand) {
      setActiveGroup([...activeGroup, item?.id]);
    }
  }, []);

  const handleClick = () => {
    onSelectItem(item);
  };
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <ListItem
        className={classes.multipleItem}
        href={item.routePath}
        button
        onClick={handleClick}
      >
        <ListItemIcon className={classes.itemIcon}>
          {activeGroup?.includes(item?.id) ? (
            <ExpandMore style={{ fontSize: 19 }} />
          ) : (
            <ChevronRight style={{ fontSize: 19 }} />
          )}
        </ListItemIcon>

        <ListItemText
          classes={{ textTransform: 'uppercase' }}
          primary={item.label}
          primaryTypographyProps={{ style: textMulti }}
        />
      </ListItem>
      <CollapseItem
        open={activeGroup?.includes(item?.id)}
        subItem={item?.childs}
      />
    </Box>
  );
};
MultipleTab.propTypes = {
  item: shape({ root: string }).isRequired,
  onSelectItem: func.isRequired
};
