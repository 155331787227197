/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import {
  Grid, Tab, Tabs, makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { HeaderWrapperComponent } from '../../components/HeaderWrapperComponent';
import ReviewJustificationComponent from '../../components/ReviewRequestComponent/ReviewJustificationComponent';
import ReviewLeaveRequestComponent from '../../components/ReviewRequestComponent/ReviewLeaveRequestComponent';
import AuthenticatedContainer from '../../containers/AuthenticatedContainer';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function MyRequest() {
  const [valueTabs, setValueTabs] = React.useState(0);
  const classes = useStyles();

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  return (
    <AuthenticatedContainer>
      <Grid container>
        <HeaderWrapperComponent title="Review Request " />
      </Grid>
      <Grid container>
        <Tabs
          value={valueTabs}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTabs}
          aria-label="disabled tabs example"
          variant="fullWidth"
          className={classes.fullWidthContainer}
        >
          <Tab label="Late/Early and Forget" />
          <Tab label="Leave" />
        </Tabs>
        <TabPanel
          className={classes.fullWidthContainer}
          value={valueTabs}
          index={0}
          {...a11yProps(0)}
        >
          <ReviewJustificationComponent fetchFlag={valueTabs === 0} />
        </TabPanel>
        <TabPanel
          className={classes.fullWidthContainer}
          value={valueTabs}
          index={1}
          {...a11yProps(1)}
        >
          <ReviewLeaveRequestComponent fetchFlag={valueTabs === 1} />
        </TabPanel>
      </Grid>
    </AuthenticatedContainer>
  );
}

export default MyRequest;
