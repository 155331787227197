import MomentUtils from '@date-io/moment';
import {
  Button, Chip, Grid, IconButton, Tooltip
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CommentIcon from '@material-ui/icons/Comment';
import SnoozeIcon from '@material-ui/icons/Snooze';
import WarningIcon from '@material-ui/icons/Warning';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import { FilterComponent } from '../../components/FilterComponent';
import { ForgetModalComponent } from '../../components/ForgetModalComponent';
import { HeaderWrapperComponent } from '../../components/HeaderWrapperComponent';
import { LateEarlyModalComponent } from '../../components/LateEarlyModalComponent';
import TimeRangeComponent, {
  orginalRange,
} from '../../components/TimeRangeComponent';
import { UserInfoComponent } from '../../components/UserInfoComponent';
import WorkFromHomeModal from '../../components/WorkFromHomeModal';
import useModal from '../../components/WorkFromHomeModal/hook';
import AuthenticatedContainer from '../../containers/AuthenticatedContainer';
import {
  convertHourTime,
  convertCheckInType,
  isWeekend,
  isFullWeekWorkScheduler,
  isToday,
} from '../../helper/helper';
import { showNotification } from '../../state/modules/notification/actions';
import {
  checkInOnline,
  requestGetLeaveInfo,
} from '../../state/modules/requests/actions';
import { requestGetTimesheet } from '../../state/modules/timesheet/actions';
import { userSelector } from '../../state/modules/user/selector';
import { checkUserRegisterWorkerSchedule } from '../../state/modules/workSchedule';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}));

const originalForm = {
  fromDate: moment().startOf('month'),
  toDate: moment().endOf('month'),
  rangeDate: 'thisMontth',
};
const rowPerPage = 50;

function Timesheet() {
  const classes = useStyles();

  /* ---------------------------------- Redux --------------------------------- */
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  /* -------------------------------------------------------------------------- */

  /* --------------------------------- States --------------------------------- */
  const [formValue, setFormValue] = useState(originalForm);
  const [dataTimesheet, setDataTimesheet] = useState([]);

  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [checkInModalOpen, openCheckInModal, closeCheckInModal] = useModal(
    false
  );
  const [viewedComment, setViewedComment] = useState(null);

  const [openModalPropsEarlyLate, setOpenModalPropsEarlyLate] = useState(false);
  const [infoModalPropsEarlyLate, setInfoModalPropsEarlyLate] = useState(null);

  const [openModalPropsForget, setOpenModalPropsForget] = useState(false);
  const [infoModalPropsForget, setInfoModalPropsForget] = useState(null);

  const [loading, setLoading] = useState(false);
  /* -------------------------------------------------------------------------- */

  const handleFetchTimeSheets = ({ filter }) => {
    if (filter.fromDate.valueOf() <= filter.toDate.valueOf()) {
      dispatch(
        requestGetTimesheet({
          filter,
          callback: (res) => {
            if (res && res.error) {
              dispatch(
                showNotification('failed', res.error.message || res.error, true)
              );
            } else {
              setDataTimesheet(res.data);
            }
          },
        })
      );
    } else {
      dispatch(
        showNotification('failed', 'FromDate must be greater than ToDate', true)
      );
    }
  };

  const handleSetFilterAndFetchTimesheets = (data) => {
    handleFetchTimeSheets({
      filter: {
        fromDate: data.fromDate.format('YYYY-MM-DD'),
        toDate: data.toDate.format('YYYY-MM-DD'),
        limit: rowPerPage,
      },
    });
  };

  useEffect(() => {
    handleSetFilterAndFetchTimesheets(originalForm);
    dispatch(checkUserRegisterWorkerSchedule());
  }, []);

  const handleFormChange = (type) => (event) => {
    let newFormValue = {
      ...formValue,
      [type]: type !== 'rangeDate' ? moment(event) : event.target.value,
    };
    if (type === 'rangeDate') {
      newFormValue = {
        ...newFormValue,
        fromDate: orginalRange[event.target.value][0],
        toDate: orginalRange[event.target.value][1],
      };
    }
    handleSetFilterAndFetchTimesheets(newFormValue);
    setFormValue(newFormValue);
  };

  const setOpenEarlyLateModal = () => {
    setOpenModalPropsEarlyLate(false);
  };

  const setOpenForgetModal = () => {
    setOpenModalPropsForget(false);
  };

  const handleCreateLateEarlyRequest = (row) => {
    setInfoModalPropsEarlyLate(row);
    setOpenModalPropsEarlyLate(true);
  };

  const handleCreateForgetRequest = (row) => {
    setInfoModalPropsForget(row);
    setOpenModalPropsForget(true);
  };

  const handleViewComment = (row) => {
    setViewedComment(row.comment);
    openCheckInModal();
  };

  const handleCreateRequest = (type, row) => () => {
    let dataProps = {};
    if (row && row.requestId) {
      dispatch(
        requestGetLeaveInfo({
          payload: {
            id: row && row.requestId,
          },
          callback: (res) => {
            if (res && res.data && res.data.id) {
              if (type === 'forget') {
                dataProps = {
                  ...row,
                  errorCount: res.data.errorCount,
                  comment: res.data.comment,
                  id: res.data.id,
                  status: res.data.status,
                  startDateTime: res.data.startDateTime,
                  endDateTime: res.data.endDateTime,
                };
                handleCreateForgetRequest(dataProps);
              } else if (type === 'late/early') {
                dataProps = {
                  ...row,
                  errorCount: res.data.errorCount,
                  comment: res.data.comment,
                  id: res.data.id,
                  status: res.data.status,
                  comp: res.data.compensationDate,
                };
                handleCreateLateEarlyRequest(dataProps);
              }
            }
          },
        })
      );
    } else if (type === 'forget') {
      handleCreateForgetRequest(row);
    } else if (type === 'late/early') {
      handleCreateLateEarlyRequest(row);
    } else if (type === 'view-comment') {
      handleViewComment(row);
    }
  };

  const handleRefreshTimeSheet = () => {
    handleSetFilterAndFetchTimesheets(formValue);
  };

  /* ----------------------------- Online check in function ----------------------------- */
  // Get check-in status
  useEffect(() => {
    if (dataTimesheet[0] && !isCheckedIn) {
      const checkedIn = moment(dataTimesheet[0].checkIn).isSame(
        new Date(),
        'day'
      );
      setIsCheckedIn(checkedIn);
    }
  }, [dataTimesheet]);
  const isFullWeekWorking = useMemo(() => isFullWeekWorkScheduler(user?.schedule), [user]);
  // Handler
  const handleOnlineCheckIn = () => {
    setViewedComment(null);
    openCheckInModal();
  };
  const onCheckIn = (comment) => {
    setLoading(true);
    dispatch(
      checkInOnline({
        comment,
        callback: (res) => {
          if (res) {
            setDataTimesheet([]);
            setFormValue(originalForm);
            handleSetFilterAndFetchTimesheets(originalForm);
            dispatch(showNotification('success', 'Successfully checked in'));
          } else {
            dispatch(
              showNotification('failed', res.error || res.error.message, true)
            );
          }
          setLoading(false);
        },
      })
    );
  };
  // Render
  const renderCheckInButton = () => {
    if (isFullWeekWorking) {
      return null;
    }
    if (dataTimesheet.length > 0 && !loading) {
      return (
        <Button
          className="checkInBtn"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleOnlineCheckIn}
          disabled={isCheckedIn}
        >
          {isCheckedIn ? '✔ Checked in' : 'Online check in'}
        </Button>
      );
    }
    // Fetching the timesheet data
    return (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        disabled
      >
        <CircularProgress style={{ height: 26.25, width: 26.25 }} />
      </Button>
    );
  };
  /* -------------------------------------------------------------------------- */

  const handleGetClassNameCell = (record, index, row) => {
    if (
      row.checkIn === null
      && row.checkOut === null
      && row.date
      && isWeekend(row.date)
    ) {
      return `${classes.leaveItem} ${classes.paddingCell}`;
    }
    return classes.paddingCell;
  };

  const handleGetClassNameCellAction = (record, index, row) => {
    if (
      row.checkIn === null
      && row.checkOut === null
      && row.date
      && isWeekend(row.date)
    ) {
      return `${classes.leaveItem} ${classes.paddingCell}`;
    }
    return classes.paddingCellNone;
  };

  const handleGetLackClassNameCell = (record, index, row) => {
    if (
      row.checkIn === null
      && row.checkOut === null
      && row.date
      && isWeekend(row.date)
    ) {
      return `${classes.leaveItem} ${classes.paddingCell}`;
    }
    if (record && record !== '00:00:00') {
      return `${classes.lateItem} ${classes.lackItem} ${classes.paddingCell}`;
    }
  };

  const handleGetCheckInOutClassName = (record, index, row) => {
    if (
      row.checkIn === null
      && row.checkOut === null
      && row.date
      && isWeekend(row.date)
    ) {
      return `${classes.leaveItem} ${classes.paddingCell}`;
    }
    if (!record && !isToday(row.date)) {
      return `${classes.lateItem} ${classes.lackItem} ${classes.paddingCell}`;
    }
  };

  const handleCheckMakeDateForgetRequest = (row) => {
    if (!row.date) return false;
    // if (row.requestTypeName && row.requestTypeName === 'forget') return true;
    let duringCheck = 3;
    if ([1, 2, 3].includes(moment().isoWeekday())) duringCheck += 2;
    const todayDate = moment();
    const checkDate = moment(row.date);
    if (todayDate.diff(checkDate, 'days') <= duringCheck) return true;
    return false;
  };

  const handleGetClassNameRowAction = (row, type) => {
    if (row.requestTypeName === type) {
      if (row.requestStatus === 'new') {
        return `${classes.actionIcon} ${classes.createdIcon}`;
      }
      return `${classes.actionIcon} ${classes.approvedIcon}`;
    }

    return classes.actionIcon;
  };

  const handleCheckMakeJutification = (row) => {
    if (row && row.lack && row.lack !== '00:00:00') return true;
    if (!row.checkIn || !row.checkOut) {
      return true;
    }
    return false;
  };

  /* ---------------------------- Disabled handler ---------------------------- */
  // View comment
  const handleDisabledViewComment = (row) => !row.checkIn || row.checkInType !== 1;

  // Forget request
  const handleDisabledForgetRequest = (row) => {
    if (!handleCheckMakeDateForgetRequest(row)) return true;
    if (!row.requestTypeName) return false;
    return (
      row.requestTypeName
      && (row.requestStatus === 'confirmed' || row.requestStatus === 'approved')
    );
  };

  // Compensation request
  const handleDisabledCompensationRequest = (row) => {
    if (!(row.checkOut && row.checkIn)) return true;
    if (!row.requestTypeName) return false;
    return (
      row.requestTypeName
      && (row.requestStatus === 'confirmed' || row.requestStatus === 'approved')
    );
  };
  /* -------------------------------------------------------------------------- */

  /* -------------------------- Render action button -------------------------- */
  const renderActions = (record, index, row) => {
    let actions = [];
    const lackWorkingTimeRelatedActions = [
      <Tooltip title="Late/Early">
        <IconButton
          className={handleGetClassNameRowAction(row, 'late/early')}
          onClick={handleCreateRequest('late/early', row)}
          disabled={handleDisabledCompensationRequest(row)}
        >
          <SnoozeIcon />
        </IconButton>
      </Tooltip>,
      <Tooltip title="Forget">
        <IconButton
          onClick={handleCreateRequest('forget', row)}
          className={handleGetClassNameRowAction(row, 'forget')}
          disabled={handleDisabledForgetRequest(row)}
        >
          <AvTimerIcon />
        </IconButton>
      </Tooltip>,
    ];
    const viewCommentAction = (
      <Tooltip title="View comment">
        <IconButton
          onClick={handleCreateRequest('view-comment', row)}
          className={handleGetClassNameRowAction(row, 'comment')}
          disabled={handleDisabledViewComment(row)}
        >
          <CommentIcon />
        </IconButton>
      </Tooltip>
    );

    const isOnlineCheckIn = row.checkInType === 1;

    // If not weekend
    if (!isWeekend(row.date)) {
      // If 'lack' column value is not null
      if (handleCheckMakeJutification(row)) {
        actions = actions.concat(lackWorkingTimeRelatedActions);
      }
      if (isOnlineCheckIn) {
        actions.push(viewCommentAction);
      }
    }

    return actions;
  };
  /* -------------------------------------------------------------------------- */

  const columns = [
    {
      field: 'date',
      label: 'Date',
      render: (record, _index, row) => {
        const date = row.checkIn || row.checkOut || record;
        return (
          <>
            {`${date
              && moment
                .utc(date, 'YYYY-MM-DDTHH:mm:ssZ')
                .local()
                .format('DD/MM/YYYY')
            } | ${date && moment(date).format('ddd')}`}
          </>
        );
      },
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'checkIn',
      label: 'Checkin',
      render: (record, rowIndex, row) => {
        if (!record && !isToday(row.date) && !isWeekend(row.date)) {
          return (
            <div className={`${classes.lateItem}`}>
              <WarningIcon />
            </div>
          );
        }
        return record && <>{convertHourTime(record)}</>;
      },
      classNameCell: handleGetCheckInOutClassName,
    },
    {
      field: 'checkOut',
      label: 'Checkout',
      render: (record, rowIndex, row) => {
        if (!record && !isToday(row.date) && !isWeekend(row.date)) {
          return (
            <div className={`${classes.lateItem}`}>
              <WarningIcon />
            </div>
          );
        }
        return record && <>{convertHourTime(record)}</>;
      },
      classNameCell: handleGetCheckInOutClassName,
    },
    {
      field: 'late',
      label: 'Late',
      render: (record) => record
        && record !== '00:00:00' && (
          <div className={`${classes.lateItem}`}>{convertHourTime(record, true)}</div>
      ),
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'early',
      label: 'Early',
      render: (record) => record
        && record !== '00:00:00' && (
          <div className={`${classes.lateItem}`}>{convertHourTime(record, true)}</div>
      ),
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'inOffice',
      label: 'In Office',
      render: (record) => record && record !== '00:00:00' && <>{convertHourTime(record)}</>,
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'overTime',
      label: 'Overtime',
      render: (record) => record && record !== '00:00:00' && convertHourTime(record),
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'workTime',
      label: 'Worktime',
      render: (record) => record && record !== '00:00:00' && convertHourTime(record),
      classNameCell: handleGetClassNameCell,
    },

    {
      field: 'lack',
      label: 'Lack',
      render: (record) => record && record !== '00:00:00' && convertHourTime(record, true),
      classNameCell: handleGetLackClassNameCell,
    },
    {
      field: 'adminNote',
      label: 'AdminNote',
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'checkInType',
      label: 'Work from home',
      render: (record) => record !== null && convertCheckInType(record),
      classNameCell: handleGetClassNameCell,
    },
    {
      field: 'inAccordance',
      label: 'Wrong schedule',
      render: (record) => record === false && (
        <Tooltip
          className={classes.iconWarning}
          title="Check-in wrong work schedule"
        >
          <WarningIcon />
        </Tooltip>
      ),
      classNameCell: handleGetClassNameCell,
    },
    {
      field: '#',
      label: 'Action',
      render: renderActions,
      classNameCell: handleGetClassNameCellAction,
    },
  ];

  return (
    <AuthenticatedContainer>
      <HeaderWrapperComponent
        title="Timesheet"
        action={(
          <>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item xs={1}>
                <div className={classes.leaveExplain} />
              </Grid>
              <Grid item xs={2}>
                <span>Weekend</span>
              </Grid>
            </Grid>
          </>
        )}
      />
      <UserInfoComponent />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* Filter */}
        <Grid xs={isFullWeekWorking ? 12 : 10}>
          <FilterComponent
            listFilterRender={(
              <Chip
                label={`From ${formValue.fromDate.format(
                  'DD/MM/YYYY'
                )} to ${formValue.toDate.format('DD/MM/YYYY')}`}
                className={classes.filterItem}
              />
            )}
            formFilter={(
              <>
                <Grid container spacing={2}>
                  <span className={classes.filterTitle}>Absoluted Range:</span>
                </Grid>
                <Grid container spacing={2}>
                  <TimeRangeComponent
                    value={formValue.rangeDate}
                    onChange={handleFormChange('rangeDate')}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <span className={classes.filterTitle}>Custome Range:</span>
                </Grid>
                <Grid container spacing={2}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid item xs={12} md={6}>
                      <KeyboardDatePicker
                        disabled={formValue.rangeDate !== 'customeRange'}
                        disableToolbar
                        variant="inline"
                        format="DD/MM/YYYY"
                        id="date-picker-inline"
                        label="From Date"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                        value={formValue.fromDate}
                        onChange={handleFormChange('fromDate')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KeyboardDatePicker
                        disabled={formValue.rangeDate !== 'customeRange'}
                        disableToolbar
                        variant="inline"
                        format="DD/MM/YYYY"
                        id="date-picker-inline"
                        label="To Date"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                        value={formValue.toDate}
                        onChange={handleFormChange('toDate')}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}
          />
        </Grid>
        {/* Online check in */}
        {!isFullWeekWorking && (
          <Grid xs={2} classes={{ root: classes.checkInBtnContainer }}>
            {renderCheckInButton()}
          </Grid>
        )}
      </Grid>
      <CustomTable
        dataSource={dataTimesheet}
        columns={columns}
        classnameHeaderCell={classes.headerTable}
        classnameBodyCell={classes.bodyTable}
      />
      <LateEarlyModalComponent
        openProps={openModalPropsEarlyLate}
        infoProps={infoModalPropsEarlyLate}
        setOpenModal={setOpenEarlyLateModal}
        refreshListFunc={handleRefreshTimeSheet}
        setInfoModal={setInfoModalPropsEarlyLate}
        startDate={formValue.fromDate}
        endDate={formValue.toDate}
      />
      <ForgetModalComponent
        openProps={openModalPropsForget}
        infoProps={infoModalPropsForget}
        setOpenModal={setOpenForgetModal}
        refreshListFunc={handleRefreshTimeSheet}
        setInfoModal={setInfoModalPropsForget}
        startDate={formValue.fromDate}
        endDate={formValue.toDate}
      />
      <WorkFromHomeModal
        startWorkingTime={
          (user
            && user.fromTime
            && moment(user.fromTime, 'HH:mm:ss').format('HH:mm'))
          || '08:30'
        }
        open={checkInModalOpen}
        onClose={closeCheckInModal}
        onCheckIn={onCheckIn}
        comment={viewedComment}
      />
    </AuthenticatedContainer>
  );
}
export default Timesheet;
