import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2F3F73'
    },
    secondary: {
      main: '#8f0a0c'
    }
  }
});

export default theme;
